* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}

/* ===========
==== FORM
=========== */

.form-banner img {
  height: 100%;
  width: 100%;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* =============
====== laoder 
=============== */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ======================
--------- navbar 
=================== */

.navbar {
  background-color: black !important;
  color: white !important;
}
.nav-link,
.navbar-brand {
  background-color: black !important;
  color: white !important;
}
